import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { compress } from 'lzutf8';
import { environment } from '../../../../environments/environment';
import { Employee, NewEmployee, AttributeTerm } from 'src/types';
import { ImportedUser, UserImportValidation } from 'src/app/organization.model';

export interface GetEmployee {
  employees: Employee[];
}

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  url = `${environment.api}/v1/employees`;
  importerUrl = `${environment.api}/v1/importer/users`;
  departmentsUrl = `${environment.api}/v1/departments`;
  private subject = new Subject<void | { data: any }>();

  constructor(private http: HttpClient) {}

  getEmployees(loadAll?: boolean): Observable<GetEmployee> {
    const url = `${this.url}${loadAll ? '?all=true' : ''}`;
    return this.http.get<GetEmployee>(url).pipe(
      map(({ employees }) => ({
        employees: employees.map((e) => ({
          ...e,
          workplaceId: e.workplaceId || e.remoteId
        }))
      }))
    );
  }

  updateEmployee(employee: Employee): Observable<Employee> {
    return this.http.put<Employee>(`${this.url}/${employee.id}`, employee);
  }

  updateEmployees(employees: {
    id: string[];
    attributeName: string;
    attributeTerms: AttributeTerm[];
  }): Observable<Employee[]> {
    return this.http.put<Employee[]>(`${this.url}/bulk-update`, employees);
  }

  addEmployee(employee: Employee): Observable<Employee> {
    employee.phone = employee.phone ? employee.phone : null;
    employee.email = employee.email ? employee.email : null;
    return this.http.post<Employee>(`${this.url}`, employee);
  }

  addEmployeeToDepartment(userId: string) {
    return this.http.post(`${this.departmentsUrl}/user`, { userId });
  }

  addEmployees(
    employees: NewEmployee[],
    replaceTerms: boolean,
    relateDepartments: boolean
  ): Observable<any> {
    const url = `${this.importerUrl}?replaceTerms=${replaceTerms}&relateDepartments=${relateDepartments}`;
    const IMPORT_BATCH_SIZE = 30000;
    const batches = [];
    const employeesCopy = [...employees];

    while (employeesCopy.length) {
      const len = Math.min(IMPORT_BATCH_SIZE, employeesCopy.length);

      batches.push(
        employeesCopy.splice(0, len).map((emp) => {
          emp.phone = emp.phone ? emp.phone : null;
          emp.email = emp.email ? emp.email : null;
          return emp;
        })
      );
    }

    const observables = batches.map((batch) => {
      const str = JSON.stringify(batch);
      const base64 = compress(str, { outputEncoding: 'Base64' });
      return this.http.post<any>(url, { base64 });
    });

    return forkJoin(observables);
  }

  validateImport(users: ImportedUser[]): Observable<UserImportValidation[][]> {
    const url = this.importerUrl + '?validationOnly=true';
    const IMPORT_BATCH_SIZE = 30000;
    const batches = [];
    const usersCopy = [...users];

    while (usersCopy.length) {
      const len = Math.min(IMPORT_BATCH_SIZE, usersCopy.length);

      batches.push(
        usersCopy.splice(0, len).map((u) => {
          u.phone = u.phone ? u.phone : null;
          u.email = u.email ? u.email : null;
          return u;
        })
      );
    }

    const observables = batches.map((batch) => {
      const str = JSON.stringify(batch);
      const base64 = compress(str, { outputEncoding: 'Base64' });
      return this.http.post<any>(url, { base64 });
    });

    return forkJoin(observables);
  }

  removeEmployees(employees: Employee[], includeOrganizationId = false) {
    let url = `${this.url}/delete`;
    const employeeIds: string[] = employees.map((employee) => employee.id);

    if (includeOrganizationId && employeeIds.length) {
      url += `?organizationId=${employees[0].organizationId}`;
    }
    return this.http.post(url, { employeeIds });
  }

  searchEmployees(user: Partial<Employee>) {
    return this.http.post<Employee[]>(`${this.url}/search`, {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email
    });
  }

  isEmailAvailable(email: string) {
    return this.http.post<{
      existsInOrg: boolean;
      existsInDept: boolean;
    }>(`${this.url}/search/email`, {
      email
    });
  }

  isPhoneAvailable(phone: string) {
    return this.http.post<{ isAvailable: boolean }>(
      `${this.url}/search/phone`,
      {
        phone
      }
    );
  }

  associateUsersWithChannel(channel: string): Observable<any> {
    return this.http.post<Partial<Employee>[]>(`${this.url}/channels`, {
      channel
    });
  }

  restoreDeletedEmployees(users: Employee[]): Observable<any> {
    return this.http.put<Partial<Employee>[]>(`${this.url}/restore`, {
      users
    });
  }

  loadDeletedEmployees(): Observable<any> {
    return this.http.get<Partial<Employee>[]>(`${this.url}/deleted`);
  }

  // Receive Message from Any Component
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  // Send Message to Any Component
  sendMessage(message: any) {
    this.subject.next({ data: message });
  }
  // Clear the Messages when the component destroyed
  clearMessages() {
    this.subject.next();
  }

  updateEmployeeRoles(employee: Partial<Employee> & { departmentId?: string }) {
    return this.http.put<Employee>(
      `${this.url}/${employee.id}/roles`,
      employee
    );
  }

  updateEmployeeLoginMethods(employee: Partial<Employee>) {
    return this.http.put<Employee>(
      `${this.url}/${employee.id}/login-methods`,
      employee
    );
  }

  hardDeleteEmployees(employees: Employee[]) {
    const employeeIds: string[] = employees.map((employee) => employee.id);
    return this.http.post<Employee[]>(`${this.url}/delete?hardDelete=true`, {
      employeeIds
    });
  }

  getAdminUsers() {
    return this.http.get<Partial<Employee[]>>(`${this.url}/admins`, {});
  }

  getNonAdminUsers(searchTerm?: string) {
    return this.http.get<Partial<Employee[]>>(
      `${this.url}/non-admins-search?${
        searchTerm ? `searchTerm=${searchTerm}` : ''
      }`,
      {}
    );
  }
}
